import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace PluginAPI {

  export class GetEntities implements APIRequest<string> {
      response: string;
      path: string;
      method = HTTPMethod.GET;
      parse = (data: AxiosResponse) => data.data
      constructor (payload: string[][]) {
        this.path = '/objecteditor/entities?' + (new URLSearchParams(payload).toString())
      }
  }
}
